<script setup>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import OfSpinner from "@/components/OfSpinner.vue";
import md5 from "js-md5";

const api = process.env.VUE_APP_API;

const sessionGuid = ref(null);

const countryCode = ref(null);

const sessionData = ref(null);

const errorMessage = ref();

const cardHolder = ref("");

const cardNumber = ref("");

const expMonth = ref("");

const expYear = ref("");

const cvc = ref("");

const loading = ref(false);

const paymentError = ref(null);

const responseHTML = ref(null);

function maskNumber(value, limit, separator) {
  let output = [];
  for (let i = 0; i < value.length; i++) {
    if (i !== 0 && i % limit === 0) {
      output.push(separator);
    }

    output.push(value[i]);
  }

  return output.join("");
}
function unmaskNumber(value) {
  return value.replace(/[^\d]/g, "");
}
function numberChange() {
  cardNumber.value = unmaskNumber(cardNumber.value);

  cardNumber.value = maskNumber(cardNumber.value, 4, " ");
}

function enforceRule(rule, e) {
  if (rule === "digits") {
    if ([191, 219].includes(e.keyCode)) {
      e.preventDefault();
    } else if (
      (e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) &&
      (e.ctrlKey === true || e.metaKey === true)
    ) {
      return;
    } else if ([8, 46, 9, 27, 13, 35, 36, 37, 39].includes(e.keyCode)) {
      return;
    } else if (e.keyCode > 47 && e.keyCode < 58) {
      return;
    } else if (e.keyCode > 95 && e.keyCode < 106) {
      return;
    }

    e.preventDefault();
  } else if (rule === "text") {
    const keyCode = e.keyCode ? e.keyCode : e.which;

    if ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) {
      e.preventDefault();
    }
  }
}
function submit() {
  if (cardNumber.value.length != 19) {
    paymentError.value = "Card number must be exactly 16 characters long";
  } else {
    paymentError.value = null;

    const data = {
      cardNumber: cardNumber.value.replace(/\s/g, ""),
      expMonth: expMonth.value,
      expYear: expYear.value,
      cvc: cvc.value,
    };

    const jsonData = JSON.stringify(data);

    loading.value = true;

    axios
      .post(sessionData.value.submitUrl, jsonData, {
        headers: {
          "Content-Type": "application/json",
          "Content-MD5": md5(jsonData),
        },
      })
      .then((response) => {

        loading.value = false;

        console.log(response);
        debugger; // eslint-disable-line no-debugger

        //  SaltPay Engine returns a HTML form which is inserted and submitted.
        //  This will navigate to a 3D Secure process and ultimately redirect to our postWait page before a visual confirmation to the donor.
        if (
          response.request.responseURL.startsWith(
            "https://saltpay.onlinefundraising.dk"
          )
        ) {

          responseHTML.value = response.data;

          const i = 0;
          const interval = setInterval(() => {
            if (document.querySelector('#submitBtn[value*="click here"]')) {
              document.querySelector('#submitBtn[value*="click here"]').click();
              clearInterval(interval);
            }

            if (i >= 5) {
              clearInterval(interval);
            }
          }, 300);
        } else {

          //  If the 3D Secure process for some reason is not required by SaltPay (Teya) then SaltPay Engine will perform a 302 to our postWait page.
          //  This will most likely fail due to CORS issues. We therefore force a redirect if the responseURL is different form the SaltPay Engine domain.
          window.location.replace(response.request.responseURL);
        }

      })
      .catch((error) => {

        loading.value = false;

        console.log(error);
        debugger; // eslint-disable-line no-debugger

        //  window.location.replace(response.request.responseURL);

        paymentError.value =
          error.response && error.response.data.errorMessage
            ? error.response.data.errorMessage
            : "Unexpected error occured";
      });
  }
}
function autotab(e, max) {
  if (e.target.value.length == max) {
    const formElements = Array.from(
      document.querySelectorAll("#payment-form input")
    );
    const focusedIndex = formElements.indexOf(e.target);
    const nextElement = formElements[focusedIndex + 1];

    if (nextElement) {
      nextElement.focus();
    }
  }
}
const validMonth = computed(() => {
  return !expMonth.value.length || expMonth.value.match(/^(0[1-9]|1[0-2])$/)
    ? true
    : false;
});

onMounted(() => {
  const params = new URLSearchParams(window.location.search);

  sessionGuid.value = params.get("sessionGuid");

  countryCode.value = params.get("countryCode") ?? "IS";

  if (sessionGuid.value) {
    loading.value = true;

    const query = `${api}/session/${sessionGuid.value}/paymentPageInfo?countryCode=${countryCode.value}`;

    axios
      .get(query, {
        headers: {},
      })
      .then((response) => {
        loading.value = false;

        console.log(response.data);

        sessionData.value = response.data;
      })
      .catch((error) => {
        loading.value = false;

        errorMessage.value =
          error.response?.data?.errorMessage ?? "Error loading session data";
      });
  }
});
</script>
<template>
  <template v-if="!sessionGuid">
    <div class="container">
      <div class="titles">
        <h1>An error occured</h1>
        <p>Please close this window and try again.</p>
      </div>
    </div>
  </template>

  <template v-if="errorMessage">
    <div class="container">
      <div class="titles">
        <h1>An error occured</h1>
        <p>{{ errorMessage }}</p>
      </div>
    </div>
  </template>

  <div v-if="sessionData && !responseHTML" class="container">
    <div class="titles">
      <h1>{{ sessionData.title }}</h1>
    </div>
    <form id="payment-form" @submit.prevent="submit">
      <div class="card-form-container">
        <label id="card-holder-label" for="card-holder">
          {{ sessionData.texts.cardHolderName }}
        </label>

        <input v-model="cardHolder" id="card-holder" @keydown="enforceRule('text', $event)" required autofocus />

        <label id="card-number-label" for="card-number">
          {{ sessionData.texts.cardNumber }}
        </label>

        <input v-model="cardNumber" id="card-number" @input="[numberChange(), autotab($event, 19)]" maxlength="19"
          @keydown="enforceRule('digits', $event)" required />

        <label id="card-month-label" for="card-month">
          {{ sessionData.texts.expMonth }}
        </label>

        <label id="card-year-label" for="card-year">
          {{ sessionData.texts.expYear }}
        </label>

        <label id="card-code-label" for="card-code">
          {{ sessionData.texts.cvc }}
        </label>

        <input v-model="expMonth" id="card-month" type="tel" maxlength="2" minlength="2"
          :class="{ invalid: !validMonth }" @input="autotab($event, 2)" @keydown="enforceRule('digits', $event)"
          placeholder="MM" required />

        <input v-model="expYear" type="tel" id="card-year" maxlength="2" minlength="2" @input="autotab($event, 2)"
          @keydown="enforceRule('digits', $event)" placeholder="YY" required />

        <input v-model="cvc" type="tel" id="card-code" maxlength="4" @keydown="enforceRule('digits', $event)"
          required />

        <div class="btns-container">
          <button type="submit">
            <img id="lock-logo" src="./assets/lock-svgrepo-com.svg" alt="Lock Logo" />
            {{ sessionData.texts.submitBtn }}
          </button>

          <form :action="sessionData.cancelUrl" method="post">
            <button type="submit">
              {{ sessionData.texts.cancelBtn }}
            </button>
          </form>
        </div>

        <p v-if="paymentError" class="payment-error">{{ paymentError }}</p>
      </div>
      <div class="logos-container">
        <div class="payment-cards-container">
          <img id="visa-logo" src="./assets/visa.svg" alt="Visa Logo" />
          <img src="./assets/mastercard.svg" alt="Mastercard Logo" />
        </div>
        <div class="teya-logo-container">
          <img id="teya-logo" src="./assets/teya.svg" alt="Teya Logo" />
        </div>
      </div>
    </form>
  </div>

  <div v-if="responseHTML" v-html="responseHTML"></div>
  <OfSpinner v-if="loading" />
</template>
<style scoped>
* {
  margin: 0;
}

form#payment-form {
  background-color: rgb(245, 245, 245);
  width: 350px;
  padding: 30px 20px 10px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
  border-radius: 12px;
  box-shadow: rgba(21, 21, 21, 0.004) 0px 0.636954px 0.636954px 0px,
    rgba(21, 21, 21, 0.01) 0px 1.9316px 1.9316px 0px,
    rgba(21, 21, 21, 0.03) 0px 5.10612px 5.10612px 0px,
    rgba(21, 21, 21, 0.1) 0px 16px 16px 0px;
}

.titles {
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem;
  text-align: center;
}

.titles h1 {
  font-size: 24px;
  line-height: 1.2em;
  margin: 0 auto 30px;
}

.card-form-container {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.3rem;
  max-width: 350px;
  padding-bottom: 1rem;
  margin-right: auto;
  margin-left: auto;

  -ms-grid-template-columns: repeat(12, minmax(0, 1fr));
  -ms-grid-column-gap: 0.5rem;
  -ms-grid-row-gap: 0.3rem;
}

.card-form-container label {
  font-weight: 400;
  font-size: 14px;
}

.card-form-container input {
  border: 1px solid #bbb;
  border-radius: 10px;
  background: #fff;
  height: 2rem;
  outline: none;
  padding-left: 5px;
  padding-top: 5px;
}

.card-form-container input.invalid {
  border-color: red;
}

.card-form-container label#card-holder-label {
  grid-column: span 12 / span 12;
  -ms-grid-column: span 12 / span 12;
}

.card-form-container input#card-holder {
  grid-column: span 12 / span 12;
  -ms-grid-column: span 12 / span 12;
  margin-bottom: 16px;
}

.card-form-container label#card-number-label {
  grid-column: span 12 / span 12;
  -ms-grid-column: span 12 / span 12;
}

.card-form-container input#card-number {
  grid-column: span 12 / span 12;
  -ms-grid-column: span 12 / span 12;
  margin-bottom: 16px;
}

.card-form-container label#card-month-label {
  grid-column: span 3 / span 3;
  -ms-grid-column: span 3 / span 3;
}

.card-form-container label#card-year-label {
  grid-column: span 3 / span 3;
  -ms-grid-column: span 3 / span 3;
}

.card-form-container label#card-code-label {
  margin-left: 1rem;
  grid-column: span 6 / span 6;
  -ms-grid-column: span 6 / span 6;
}

.card-form-container input#card-month {
  grid-column: span 3 / span 3;
  -ms-grid-column: span 3 / span 3;
}

.card-form-container input#card-year {
  grid-column: span 3 / span 3;
  -ms-grid-column: span 3 / span 3;
}

.card-form-container input#card-code {
  margin-left: 1rem;
  grid-column: span 6 / span 6;
  -ms-grid-column: span 6 / span 6;
}

.logos-container {
  display: grid;
  width: auto;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  padding-top: 25px;
}

.btns-container {
  width: 350px;
  display: grid;
  justify-content: center;
}

.payment-cards-container {
  display: flex;
  border-radius: 10px;
  width: 100%;
  justify-content: left;
}

.payment-cards-container img {
  width: 50px;
}

.teya-logo-container {
  width: 100%;
  display: flex;
  justify-content: right;
}

#teya-logo {
  width: 75px;
}

#visa-logo {
  width: 100px;
  margin-left: -20px;
}

#lock-logo {
  width: 12px;
  margin-right: 2px;
}

.card-form-container button {
  grid-column: span 12 / span 12;
  width: 100%;
  margin-top: 2rem;
  color: #454545;
  background-color: rgb(221, 224, 72);
  padding: 11px;
  line-height: 16px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 10px;

  -ms-grid-column: span 12 / span 12;
}

.card-form-container form {
  width: 155px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.card-form-container form button {
  margin: 0;
  color: #999;
  background-color: #f5f6f8;
  font-size: small;
  padding: 0;
  width: auto;
}

.card-form-container form button:hover {
  color: #c9302c;
}

p.payment-error {
  color: #c9302c;
  margin-top: 0.5rem;
  grid-column: span 12 / span 12;
  -ms-grid-column: span 12 / span 12;
}

p.success-message {
  color: #38b249;
}

#iframe-wrapper {
  max-width: 500px;
  max-height: 600px;
  text-align: center;
  margin: 0 auto;
}
</style>
<style>
body {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 100px;
}
</style>
